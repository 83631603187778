<template>
    <div>
        <h3>{{ heading }}</h3>
        <div v-for="(rm) in data" v-bind:key="rm.id" class="panel material_panel">
            <label>{{ rm.raw_material }}</label>
            <table>
            <td v-for="field in state.object.fields" v-bind:key="rm[field.id]">
                <component :is="field.component"
                           :title="field.label"
                           :field="field.name"
                           :val="rm[field.name]"
                           :id="rm.raw_material_id"
                           :class="`number`"
                           @save="save_inventory"
                />
            </td>
            <td><button class="btn btn-default" style="margin-right: 15px; margin-left:15px" @click="get_history(rm)">History</button></td>
            <td><button class="btn btn-default" style="margin-right: 15px; margin-left:15px" @click="get_prices(rm)">Prices</button></td>
            </table>
        </div>

        <Modal  v-if="showHistoryModal" heading="History" @close=" closeModal()">
            <template v-slot:body>
                <div class="form-group" v-if="history">
                    <label><b>{{ currentItem.raw_material }}</b></label>
                    <span v-for='(event) in history' v-bind:key='event.id'>
                        <p>{{ event }}<br /></p> </span>
                </div>
            </template>
        </Modal>

        <Modal v-if="showPricesModal" heading="Prices" @close=" closeModal()">
            <template v-slot:body>
                <div class="form-group" v-if="prices">
                    <label><b>{{ currentItem.raw_material }}</b></label>
                    <table class='table table-striped'>
                        <thead>
                        <tr>
                            <th>Company Name:</th>
                            <th class="number">Price:</th>
                            <th class="number">Lead Time:</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for='(price) in prices' v-bind:key='price.id'>
                                <td>{{ price.company_name }}</td>
                                <td class="number">${{ price.company_price | financial }}</td>
                                <td class="number">{{ price.lead_time }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </template>
        </Modal>

    </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store.js";
import DetailsStatic from "@/components/utils/DetailsStatic";
import DetailsText from "@/components/utils/DetailsText";
import Modal from "@/components/tools/InventoryManager/Modal";
import Walls from "@/components/businessObjects/quote/AutoDrawings/Walls";

export default {
    name:"MaterialDetail",
    props: ['data' ,'heading'],
    components: {
        Walls,
        DetailsStatic,
        DetailsText,
        Modal,
    },
    data() {
        return {
            history: '',
            prices: '',
            showHistoryModal: false,
            showPricesModal: false,
            currentItem: '',
            cdiLocationID: 1,
            results: {},

            state: store.state,
        }
    },
    methods: {
        save_inventory: function (item) {
            this.$emit('save', item);
        },
        get_history: function (item) {
            this.currentItem = item;
            this.showHistoryModal = true;
            let params = {};
            params.params = {};
            params.params = {
                limit: 1
            };
            store.customApi(`material/history_json/${item.raw_material_id}`, params)
                .then(results => {
                    this.history = results;
                });
        },
        get_prices: function (item) {
            this.currentItem = item;
            this.showPricesModal = true;
            store.customApi(`/material/get_prices_json/${item.raw_material_id}`, {})
                .then(results => {
                    this.prices = results.prices;
                });
        },
        closeModal: function () {
            this.history = {};
            this.prices = {};
            this.showHistoryModal = false;
            this.showPricesModal = false;
        },
        isCurrentView: function(view) {
            return view === this.state.currentView;
        }

    },
    filters: {
        financial: function (value) {
            value = parseFloat(value).toFixed(2);
            return value;
        }
    }
}
</script>
<style scoped>
.material_panel {
    padding: 5px 10px;
    margin-bottom: 15px;
    border: 1px solid #D4D8DD;
    overflow: auto;
    min-height: 110px;
}

</style>