<template>
    <transition name="modal_fade" >
        <div  @keydown.esc="$emit('close')">
            <div class="modal" role="dialog" v-draggable>
                <div class="modal_header">
                    {{heading}}
                    <button type="button" class="modal_btn_close" @click="$emit('close')" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                </div>

                <div class="modal_body">
                    <slot name="body"></slot>
                </div>

                <div class="modal_footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import draggable from '@/directives/draggable';

export default {
    name: "Modal",
    props: ['heading'],
    directives: {
        draggable
    }
}
</script>

<style scoped>
.modal{
    position:  fixed;
    width: 600px;
    top: 600px;
    bottom: 40px;
    z-index: 100;
    overflow-y: visible!important;
    overflow-y: auto!important;
}
</style>